<template>
  <div class="row about pb-3 p-lg-5" style="position: relative; overflow: hidden;">

    <!-- About Content -->
    <div class="col-12 mx-auto d-flex justify-content-between" style="max-width: 1400px; position: relative;">
      <!-- Mobile Top Cookie -->
      <img src="../assets/cbd-cookie-real.png" alt="cbd cookie" class="cbd-small-cookie d-flex d-lg-none" />  
      <div class=" col-12 col-lg-7 about-content px-4 pb-4 p-lg-5 d-flex flex-column justify-content-center mx-auto mx-lg-0 my-5">
        <h3 class="montserrat-header-large text-green text-center text-lg-start my-4 px-xl-5">Our Story</h3>
        <p class="montserrat-paragraph text-green px-xl-5" style="line-height: 33px;">Since founding <a href="https://www.theblakeryli.com/" class="text-underline" style="color: #2c9c89; font-weight: 700;">The Blakery,</a> the sister bakery to Get Blaked, Blake's passion for baking and delighting tastebuds has grown into a never-ending quest to satisfy everyone. Although The Blakery’s 6 ounce insanely delicious cookies offer a large amount of euphoria, Get Blaked offers an experience like no other. He wanted to create a treat that tasted amazing, with added benefits for people who suffer with anxiety, insomnia, or just want some added R&R! </p>
        <p class="montserrat-paragraph text-green px-xl-5" style="line-height: 33px;">With some butter, sugar, love, and a whole lotta CBD, he was able to Blake up the perfect treat. All products from Get Blaked contain CBD Ultra Broad-Spectrum Distillate which is one of the purest forms on the market. So, if you’re looking to up the chill factor in your life, it’s time to Get Blaked! </p>
        <div class="creator-section d-flex align-items-center px-xl-5">
          <img src="../assets/founder-image.png" class="img-fluid founder-image" alt="founder headshot" style="margin-right: 20px;" />
          <h2 class="text-green eldwin-large">Blake Warman</h2>
        </div>
      </div>

      <!-- Desktop Blakery Logo -->
      <div class="col-2 mx-5 mt-5 d-none d-lg-block" style="width: 250px;">
        <a target="blank_" href="https://www.theblakeryli.com/">
          <div class="blakery-logo-section-desktop d-none d-lg-flex flex-column align-items-start mt-5">
            <img src="../assets/blakery_logo.png" alt="blakery logo" class="img-fluid blakery-logo" />
            <div class="arrow-text d-flex justify-content-between">
              <p class="text-green text-center eldwin-regular">Want more flavors?</p>
              <img src="../assets/arrow-desktop.png" alt="arrow pointing to cookies" class="img-fluid arrow" />
            </div>
          </div>
        </a>
      </div>
    </div>
    
    <!-- Desktop Cookies -->
    <div class="cookies-section p-0">
      <img src="../assets/about-cookies.png" alt="blakery cookies" class="img-fluid d-none d-lg-block" />
    </div>
    <!-- Mobile Cookies -->
    <div class="cookies-mobile p-0 d-flex align-items-end justify-content-center">
      <a target="blank_" href="https://www.theblakeryli.com/">
        <div class="blakery-logo-section d-lg-none">
          <img src="../assets/blakery_logo.png" alt="blakery logo" class="img-fluid blakery-logo" />
          <p class="text-green text-center eldwin-regular">Want more <br>flavors?</p>
        </div>
      </a>
      <img src="../assets/arrow-mobile.png" alt="arrow pointing to cookies" class="d-lg-none img-fluid arrow" />
      <img src="../assets/cookie-stack.png" alt="blakery cookies" class="cookie-stack img-fluid d-block d-lg-none" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="scss" scoped>

.about {
  background-color: #c3eee8;
  padding-top: 60px;

  a {
    text-decoration: none;
  }

  .about-content {
    background-color: #ffffff;
    max-width: 900px;
    padding-top: 60px;

    .founder-image {
      width: 80px;

      @media(min-width: 992px) {
        width: 120px;
      }
    }
  }

  // Mobile Top Cookie
  .cbd-small-cookie {
    position: absolute;
    top: 3%;
    left: 50%;
    width: 200px;
    height: 180px;
    margin-top: -90px;
    margin-left: -100px; 

    @media(min-width: 576px) {
      top: 5%;
    }
  }

  // Desktop Cookies
  .cookies-section {
    position: absolute;
    bottom: -64px;
    right: -100px;
    width: auto;

    @media(min-width: 1200px) {
      right: 0;
    }

    img {
      width: 800px;
    }
  }

  // Desktop Blakery Logo
  .blakery-logo-section-desktop {

    .blakery-logo {
      max-width: 160px;
    }

    .arrow {
      max-width: 60px;
      margin-left: 20px;
    }
  }

  // Mobile Cookies
  .cookies-mobile {
    position: relative;
    height: 400px;


    @media(min-width: 992px) {
      height: 200px;
    }

    .blakery-logo {
      max-width: 120px;
    }

    .arrow {
      max-width: 50px;
      margin-bottom: 30px;
      margin-left: 10px;
      margin-right: 50px;
    }

    .cookie-stack {
      position: absolute;
      bottom: -15px;
      right: -180px;
      max-width: 390px;

      @media(min-width: 576px) {
        right: -18px;
      }
    }
  }
}

</style>